import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentTab: 0
  },
  getters: {
  },
  mutations: {
    currentTab: (state, option) => {
      state.currentTab = option
    },
  },
  actions: {
    currentTab: (context, option) => {
      context.commit('currentTab', option)
    },
  },
  modules: {
  }
})
