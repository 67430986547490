<template>
  <div>
    <div v-if="currentTab == 0">
      <v-card>
        <v-card-title class="red--text text--darken-4">BIO</v-card-title>
        <v-card-text>
          <p>
            Dr. Zahir Javed Paracha is an HEC approved PhD Supervisor. He
            successfully won AUSTRALIAN POSTGRADUATE AWARD and completed his PhD
            degree in Electrical Engineering from Victorila University Melbourne
            Australia. He has over 30 years comprehensive range of experience
            which includes academic leadership, strategic academic policy
            planning & development, academic governance and administration,
            teaching & research, human resource development skills development,
            teaching & research
          </p>
        </v-card-text>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4">MEDIA</v-card-title>
        <v-card-text>
          <v-slide-group show-arrows>
            <v-slide-item v-for="(n, index) in items" :key="index">
              <v-card
                color="grey lighten-1"
                class="ma-1"
                height="200"
                width="300"
              >
                <v-img
                  :src="n.src"
                  class="white--text align-end"
                  height="200px"
                ></v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card-text>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4"
          >ACADEMIC POSITIONS</v-card-title
        >
        <ul>
          <li
            v-for="(item, index) in academics"
            :key="index"
            :class="index % 2 ? 'red lighten-4' : ''"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.from }} - {{ item.dated }}</p>
          </li>
        </ul>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4"
          >NON-ACADEMIC POSITIONS</v-card-title
        >
        <ul>
          <li
            v-for="(item, index) in nonacademics"
            :key="index"
            :class="index % 2 ? 'red lighten-4' : ''"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.from }} - {{ item.dated }}</p>
          </li>
        </ul>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4">DEGREES</v-card-title>
        <ul>
          <li
            v-for="(item, index) in degrees"
            :key="index"
            :class="index % 2 ? 'red lighten-4' : ''"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.from }} - {{ item.dated }}</p>
          </li>
        </ul>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4"
          >CERTIFICATIONS</v-card-title
        >
        <ul>
          <li
            v-for="(item, index) in certifications"
            :key="index"
            :class="index % 2 ? 'red lighten-4' : ''"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.dated }}</p>
          </li>
        </ul>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4">LANGUAGES</v-card-title>
        <v-card-text>
          <v-chip
            color="red lighten-4 mr-2"
            v-for="(item, index) in languages"
            :key="index"
            ><strong>{{ item }}</strong></v-chip
          >
        </v-card-text>
      </v-card>
      <div class="pa-1"></div>
      <v-card>
        <v-card-title class="red--text text--darken-4">TAGS</v-card-title>
        <v-card-text>
          <v-chip
            color="red lighten-4 mr-2"
            v-for="(item, index) in tags"
            :key="index"
          >
            <v-avatar left>
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
            <strong>{{ item.title }}</strong>
          </v-chip>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="currentTab == 1">
      <v-card>
        <ul>
          <li
            v-for="(item, index) in leadership"
            :key="index"
            :class="index % 2 ? 'red lighten-4' : ''"
          >
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </v-card>
    </div>
    <div v-if="currentTab == 2">
      <v-card v-for="(item, index) in acontributions" :key="index" class="mb-2">
        <v-card-text>
          <h4>{{ item.title }}</h4>
          <p v-if="item.subtitle != ''">{{ item.subtitle }}</p>
          <ul>
            <li
              v-for="(subitem, index) in item.contributions"
              :key="index"
              :class="index % 2 ? 'red lighten-4' : ''"
            >
              {{ subitem }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="currentTab == 3">
      <v-card v-for="(item, index) in industrialExp" :key="index" class="mb-2">
        <v-card-text>
          <h4>{{ item.title }}</h4>
          <p v-if="item.subtitle != ''">{{ item.subtitle }}</p>
          <ul>
            <li
              v-for="(subitem, index) in item.exp"
              :key="index"
              :class="index % 2 ? 'red lighten-4' : ''"
            >
              {{ subitem }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="currentTab == 4">
      <v-card>
        <v-card-text>Under Development, please check back later</v-card-text>
      </v-card>
    </div>
    <div v-if="currentTab == 5">
      <v-card v-for="(item, index) in research" :key="index" class="mb-2">
        <v-card-text>
          <h4>{{ item.title }}</h4>
          <p v-if="item.subtitle != ''">{{ item.subtitle }}</p>
          <ul>
            <li
              v-for="(subitem, index) in item.exp"
              :key="index"
              :class="index % 2 ? 'red lighten-4' : ''"
            >
              {{ subitem }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data: () => ({
    items: [
      { src: "./img/1.jpeg" },
      { src: "./img/2.jpeg" },
      { src: "./img/3.jpeg" },
      { src: "./img/4.jpeg" },
      { src: "./img/5.jpeg" },
      { src: "./img/6.jpeg" },
      { src: "./img/7.jpeg" },
      { src: "./img/8.jpeg" },
      { src: "./img/9.jpeg" },
      { src: "./img/10.jpeg" },
      { src: "./img/11.jpeg" },
    ],
    pics: [
      { src: "./img/12.png" },
      { src: "./img/13.jpeg" },
      { src: "./img/14.jpeg" },
      { src: "./img/15.jpeg" },
    ],
    logos: [
      { src: "./img/16.png" },
      { src: "./img/17.png" },
      { src: "./img/18.jpeg" },
      { src: "./img/19.png" },
    ],
    academics: [
      {
        title: "Pro Rector",
        from: "The University of Faisalabad",
        dated: "2017(July)- Date",
      },
      {
        title: "Pro Vice-Chancellor",
        from: "Qarshi University Lahore",
        dated: "2014-2017",
      },
      {
        title: "Professor/HOD Computer Engineering Department",
        from: "The University of Lahore",
        dated: "2013-2014",
      },
      {
        title: "Expert TVET/Principal",
        from: "Swedish College of Engineering and Technology Rahim Yar Khan",
        dated: "2013-2013",
      },
      {
        title: "PhD Research Scholar/Teaching Training & Consultancy",
        from: "Victoria University Australia",
        dated: "2007-2012",
      },
      {
        title: "HOD Electrical Engineering",
        from: "UCET- Bahauddin Zakariya University Multan",
        dated: "2001-2002",
      },
      {
        title: "Visiting Faculty",
        from: "Illawara Institute of Technology TAFE, Wollongong Australia",
        dated: "1995-1996",
      },
    ],
    nonacademics: [
      {
        title: "Assistant Director",
        from: "Wapda Pakistan",
        dated: "1990-1995",
      },
      {
        title: "National Expert-Pakistan",
        from: "Asian Productivity Organization Japan / NPO Pakistan",
        dated: "2015-2016",
      },
      {
        title: "General Manager Academics",
        from: "Technical Education and Vocational Training Authority Punjab",
        dated: "2002-2007",
      },
      {
        title: "Training Consultant Change Management",
        from: "AGC Sydney Australia",
        dated: "1999-2000",
      },
      {
        title: "Project Manager",
        from: "Astina Electronics Singapore",
        dated: "1997-1998",
      },
      {
        title: "Assistant Director",
        from: "Wapda Pakistan",
        dated: "1990-1995",
      },
    ],
    degrees: [
      {
        title: "PhD Electrical Engineering",
        from: "Victoria University, Australia",
        dated: "2012",
      },
      {
        title: "Research Commercialization Program",
        from: "The University of Melbourne, Australia",
        dated: "2010",
      },
      {
        title: "Master of Science in Total Quality Management",
        from: "University of Punjab, Lahore Pakistan",
        dated: "2005",
      },
      {
        title: "Management Communication Graduate Program",
        from: "Southern Sydney Institute of TAFE, Australia",
        dated: "2000",
      },
      {
        title: "Master of Engineering",
        from: "University of Wollongong, Australia",
        dated: "1997",
      },
      {
        title: "Bachelor of Science in Electrical Engineering",
        from: "University of Engineering & Technology Lahore, Pakistan",
        dated: "1989",
      },
    ],
    certifications: [
      {
        title:
          "Certificate of Recognition for being resource person during the IN-Country Program on “Entrepreneurial Skill Development for TEVTA Female Teachers” by Colombo Plan Staff College for Technician Education",
        dated: "",
      },
      {
        title:
          "Person of the month for training of teachers- Intel Teach to the future.",
        dated: "2005",
      },
      {
        title: "External Examiner for Central Queensland University Australia.",
        dated: "",
      },
      {
        title:
          "National Expert NPO/APO Japan for Research on Institutions offering productivity courses.",
        dated: "",
      },
      {
        title:
          "Certificate of Appreciation in recognition of valuable contribution to Postgraduate Research Seminar Victoria University Melbourne Australia.",
        dated: "2009",
      },
      {
        title:
          "Head Working Group on TEVTA Teachers Qualifications in Pakistan constituted by Executive Director National Vocational and Technical Education Commission (NAVTEC now NAVTTC).",
        dated: "",
      },
      {
        title:
          "Member of National Committee on Technical Education under Pakistan Council for Science and Technology (PCST) Islamabad.",
        dated: "2006",
      },
      {
        title:
          "Certificate of Honor for being expert panelist on enhancing quality of TEVTA in institutes Pakistan Institute of Quality Control.",
        dated: "2005",
      },
      {
        title:
          "Member National Committee for Service Sector for Pakistan National Quality Award by National Productivity Organization.",
        dated: "2005",
      },
      {
        title:
          "Member Academic Committee University of Engineering and Technology Lahore.",
        dated: "2002-07",
      },
      {
        title: "President Islamic Society of Victoria University Australia.",
        dated: "",
      },
      {
        title:
          "Participated in Australian Corporate Games as member of AGC Tennis Team",
        dated: "",
      },
    ],
    languages: ["English", "Urdu", "Punjabi"],
    tags: [
      { title: "Facebook", icon: "mdi-facebook" },
      { title: "LinkedIn", icon: "mdi-linkedin" },
    ],
    leadership: [
      { title: "Pro Rector The University of Faisalabad" },
      {
        title:
          "National Expert APO/NPO for Research on Institutions offering productivity courses",
      },
      {
        title:
          "Pro VC Qarshi University, Overall academic and administrative management of Qarshi University",
      },
      {
        title:
          "Professor & Head Computer Engineering Department, Establishment of new department at University of Lahore",
      },
      {
        title:
          "Principal Swedish College of Engineering and Technology, accreditation of engineering programs from Pakistan Engineering Council",
      },
      {
        title:
          "TVET Expert, launch of skill development Courses in collaboration with Punjab Skill Development Fund",
      },
      {
        title:
          "PhD Research Scholar/ Consultant United Energy Melbourne Australia",
      },
      {
        title:
          "General Manager Academics, TEVTA, Responsible for overall management, curriculum development, training and skill upgradation for 400+ technical and vocational institutes",
      },
      {
        title:
          "Expert NAVTTC, Head of working group at national level for formulation of national qualification system for teachers",
      },
      { title: "Member PBTE for regulation of examination in Punjab" },
      {
        title:
          "Head Working Group on TVET Teachers Qualifications in Pakistan constituted by Executive Director National Vocational and Technical Education Commission (NAVTTC)",
      },
      {
        title:
          "Person of the month for training of teachers- Intel Teach to the future",
      },
      {
        title:
          "Resource person  IN-Country Program on “Entrepreneurial Skill Development for TVET Female Teachers” by Colombo Plan Staff College for Technician Education",
      },
      {
        title:
          "Team leader for “Multi-Country Study Mission on IN-company Training programs” organized by Korea Productivity Center (KPC) The other team members were from Malaysia, Singapore, Thailand and Sri Lanka",
      },
      {
        title:
          "Expert Panelist on the topic “How to Setup Quality Cell in Vocational Institutes in Pakistan, in 4th National Conference on Quality Assurance in Education in Lahore",
      },
      {
        title:
          "Member National Committee for Service Sector for Pakistan National Quality Award by National Productivity Organization",
      },
      { title: "Member Punjab Board of Technical Education" },
      {
        title:
          "Expert panelist on enhancing quality of TVET in institutes – Pakistan Institute of Quality Control",
      },
      {
        title:
          "Member Academic Committee University of Engineering and Technology Lahore",
      },
    ],
    acontributions: [
      {
        title:
          "Research on Institutions offering productivity courses in Pakistan (APO Japan)",
        subtitle:
          "Worked with APO Japan and conducted research on institutions offering productivity courses in Pakistan. The main features of this study are:",
        contributions: [
          "Existing national scenario",
          "Education and training system in Pakistan",
          "Evolution of productivity management system",
          "Evaluation of existing productivity courses",
          "The institutions offering productivity courses in Pakistan",
          "Strategic actions and improvement plans",
          "Action plan for Implementation",
        ],
      },
      {
        title: "Establishment of new university (Qarshi University Lahore)",
        subtitle: "",
        contributions: [
          "Developed a complete mechanism for vision implementation for producing managers of change in society as per Qarshi culture and values",
          "Established departments of Treasury, Academic Planning Unit, Quality Enhancement Cell Students Affairs Office, Career Placement Office, IT Department & Examination Cell & improved working of examination cell as per charter & HEC requirements",
          "Developed  various statutes, rules and Sops and got them approved from Board of Governors",
          "Developed Operational Manual of Qarshi University which includes admission & migration Policies, examination rules and regulations for Semester and annual system examination, students’ handbook, handbook of quality assurance, financial policy ,plagiarism Policy, Academic planning standards, students’ grievance system and library polices.",
          "Successfully established and supervised the conduction of  various meetings board of studies, ,academic council, disciplinary Committee, examination Committee, Unfair Means Committee, grievance Committee, university equivalence Committee, financial assistance committee, research ethics Committee as per charter & HEC requirements",
          "Upgraded Labs & internet facilities and Launched application for QU W2 category after meeting the requirements of HEC",
          "Supervised & developed 10 year master plan for new departments for university keeping the guidelines of HEC & PEC",
          "Streamlined fee recovery mechanism & developed standard operating procedure SOP for fee recovery of students",
          "Supervised the development of university annual budget for 2014-2015, 2015-2016, and 2016-2017.",
          "Launched Application for accreditation of various programs in Business management, commerce and computer science department with relevant regulatory bodies ",
          "Got the design & content of transcript and degree approved from BoG for 1st passing out batch of QU and got them printed from Printing Corporation of Pakistan",
        ],
      },
      {
        title:
          "Establishment of computer engineering department (The University of Lahore)",
        subtitle: "",
        contributions: [
          "Launched new 4 years new program of Bachelors of Science in Computer Engineering",
          "Established electronics, electricity & magnetism, advance networks & general computer labs of department",
          "Got the department approved  for 80 seats  for Bachelors of computer engineering program from Pakistan engineering council",
        ],
      },
      {
        title:
          "Accreditation of engineering programs with PEC and launch of new six technical vocational programs with PSDF (Swedish College of Engineering and Technology Rahim Yar Khan Pakistan)",
        subtitle: "",
        contributions: [
          "Launched six vocational courses of 3 months to 1 year duration",
          "TVET expert for Technical , Vocational Education and Training (TVET) for 9  technical institutes under Swedish Group  running DAE program in Electrical, Electronics, Mechanical ,Chemical and Computer information Technology",
          "Got the electrical engineering & mechanical engineering programs accredited from Pakistan engineering",
        ],
      },
      {
        title:
          "Design and development of intelligent computational techniques for power quality data monitoring and management (Victoria University Australia)",
        subtitle: "",
        contributions: [
          "Developed a new intelligent strategy to manage the quality of electrical supply of  power system",
          "Designed intelligent computational techniques to enhance the efficiency reliability and stability of the power distribution system",
          "Analysis of power quality parameters for 66/22kv zone substations for management of power quality for united energy distribution system Australia",
        ],
      },
      {
        title:
          "TVET reform policy formulation (Technical Education & Vocational Training Authority Punjab)",
        subtitle: "",
        contributions: [
          "Developed 3 years Strategic Plan for imparting technical, vocational and commerce education in more than 400 institutes under the government of Punjab",
          "Developed TVET short courses based on working with the competency based training (CBT) approach and manuals with the help of major industry partners in electrical, civil, mechanical and textile sector for imparting employable skills under TEVT special training program. ",
          "Provide standardized and credible TEVT assessment/evaluation and certification below degree level within the territorial limits of the province of the Punjab and such other areas as may be notified by the government; Evaluated the development and process of integration and how far the Technical Education system has established the linkages with other projects on TVET in Pakistan",
          "Designed Academic Model for the development and promotion of Academic programs in various sectors for technical, vocational and commerce institutes spread all over Punjab. ",
          "Contributed towards the establishment of technical and vocational training of The National Think Tank on technical and vocational Education",
          "Launched HND courses focused on competency were signed for on Electrical job training, placement, and training of trainers with Siemens, Nokia , Telenor and various MOU’s",
        ],
      },
      {
        title:
          "TVET teacher’s qualifications in Pakistan (National Vocational & Technical Training Commission Islamabad -NAVTTC)",
        subtitle: "",
        contributions: [
          "NAVTTC is an apex body at national level to regulate, facilitate and provide policy direction in Vocational & Technical Training; Review and evaluate the existing practices in the design, implementation and evaluation of TVET curriculum practiced by the teachers, an develop a training program particularly in the development of competency-based curriculum, in the field of technical and vocational education and Training facilitating, and exchange of experiences among the participating TVET Institutions",
          "Developed National Qualification framework for TVET teachers and linked qualification with promotion of teachers in TVET sector",
        ],
      },
      {
        title:
          "Conduction of training courses (SAARC member countries & Korea Productivity Center Seoul, Korea)",
        subtitle: "",
        contributions: [
          "Conducted training to the participants from SAARC member countries and gave lecture on the topic “Role of Technical and Vocational Education in Youth Employment Generation in South Asian context ; also presented the   impact of culture and gender issues ",
          "Developed competency based training model as “Multi-Country Study Mission on IN-company Training programs",
          "The model was developed based on the study of in-company training and development activities conducted by the private and public enterprises of the host country and examine the Korean approach to needs identification, program development, implementation, and evaluation. Also review the Steps required to perform tasks;  Knowledge and skills required to perform tasks; Safety factors; Tools, equipment and supplies; Worker decisions that may affect",
          "In-Country Program reviewed the key concept of PPP for expanding job opportunities and the growth of the linkages with TVET in Pakistan; also reviewed the entrepreneurial skill development for TVET Female Teachers for Technician Education",
        ],
      },
      {
        title:
          "Quality of TVET in institutes (PIQC & Intel training of teachers)",
        subtitle: "",
        contributions: [
          "Expert panelist on enhancing Quality Of TVET In Institutes, review, understand and explain the paradigms of total quality management (TQM); identify the applications of Quality Tools and Techniques in implementing Total Quality Management for TVET institutions and prepare an action plan for implementing TQM at TVET institutions and organizations for constant improvement",
          "'Role of Academic Performance Audit for Quality Assurance in Technical and Vocational Training' at Pakistan’s first National Conference on Quality Assurance in Education.",
        ],
      },
      {
        title: "Intel Teach to the future Program",
        subtitle: "",
        contributions: [
          "More than 1000 teachers were trained in TEVTA under training of teachers- Intel Teach to the future with continuous efforts and interest in professional development of teachers in integrated IT skills.",
        ],
      },
      {
        title:
          "Implementation of change management system (Australian Guarantee Corporation Limited Australia)",
        subtitle: "",
        contributions: [
          "Reviewed and recommended comprehensive human resource development strategies for strengthening the implementation ICBS system at AGC ",
          "Successfully implemented Change Management system within the organization; Master trainer for newly inducted officers who were trained to learn the new software and techniques to facilitate customers and handle conflict management; develop a of competency standard training to upgrade the present system of pre- and in- customer service training; train on sensitive issues related to multicultural aspects and gender for customer service",
          "Developed the energy saving plan for large Industrial consumers of electricity in Singapore and ",
        ],
      },
      {
        title:
          "Establishment of new department and accreditation of electrical engineering program with PEC (University College of Engineering &Technology Bahaudin Zakariya University Multan)",
        subtitle: "",
        contributions: [
          "Developed 11 engineering labs for accreditation of Electrical Engineering Program for UCET¬BZU from Pakistan Engineering Council",
          "Supervised the teaching and administrative staff and conducted trainings for staff development",
        ],
      },
      {
        title:
          "Establishment of R&D and testing facilities (Astina Electronics Singapore)",
        subtitle: "",
        contributions: [
          "Established R&D facilities at Astina Electronics Singapore for energy saving equipment",
          "Supervised the testing and approval of energy saving products through productivity and standard board of Singapore.",
        ],
      },
      {
        title:
          "Development of new mat lab code for variable speed drives for engineering students (University of Wollongong/ Illawara Institute of Technology TAFE NSW Australia)",
        subtitle: "",
        contributions: [
          "Taught electrical and electronics engineering subjects in engineering services division Illawara institute of Technology Australia ",
          "Developed the MATLAB code to control the output speed of electrical motors associated with the variable speed drives.",
          "Investigated the maintenance requirements for the power distribution system under maintenance management and system engineering program in Wollongong University.",
        ],
      },
    ],
    industrialExp: [
      {
        title:
          "Project principal investigator power distribution network united energy power distribution network Australia",
        subtitle: "",
        exp: [
          "Investigated the power quality issues for the 66 / 22 KV zone substations for the united energy power distribution network in Victoria.",
          "Design / developed intelligent computational techniques to enhance the reliability and stability of the power distribution system.",
          "Comprehensive analysis of the power quality problems of sag, swell, voltage unbalance, poor power factor, voltage fluctuation and transients including harmonics and system frequency.",
          "Developed a strategy to manage the quality of electrical supply power which is most important requirement of its customers at all times.",
          "Developed a model to apply principal component analysis technique to convert huge power quality data to two dimensional data without the loss of information for the power distribution network.",
          "Used neural networks and fuzzy clustering techniques to separate the useful data from raw data for intelligent decision making such as power system re-designs construction, operation and maintenance of network to manage the ever increasing customer base.",
          "Characterization of electromagnetic phenomenon at a particular location of the network and help Power utilities to manage their large networks for PQ monitoring.",
          "Developed guide lines for clients for installation of electrical equipment with allowable PQ standards.",
          "Compiled a report for the implementation of uniform standards across board. At present different power utilities operate on different standards and there is absence of uniformity",
        ],
      },
      {
        title:
          "Project engineer power QCLNG up stream projects QGC – Australia<br/>Senior network engineer power core Melbourne Australia",
        subtitle:
          "Review / Approve Electrical Design Criteria’s , Philosophies for QCLNG Up Stream HV / LV infrastructures 132-33kV, 690, 415 HV, LV Power Distribution Systems which include:",
        exp: [
          "Electrical Design Criteria 132/33kV substations",
          "Electrical Distribution Philosophy",
          "Electrical Protection philosophy",
          "Metering Philosophy Statistical and Power Quality/Revenue and Check",
          "Power Management system Design Philosophy",
          "Substation Automation System Design Philosophy(33kV)",
          "Electrical Operating Philosophy",
          "Electrical Control Philosophy",
          "Emergency shutdown Philosophy",
          "Fire and Gas Philosophy",
          "Approve Power System Studies and key Reports for Power System Model for the QCLNG Up Stream projects.",
          "Develop Cooperate Electrical standards gap analysis and action plan or implementation in detailed electrical design.",
          "Technical bid evaluation for primary and secondary electrical packages for 132kV electrical substations.",
          "Update procurement status report (PSR) and close liaison with design engineering contractor to meet the dead lines",
        ],
      },
      {
        title:
          "Training consultant Australian guarantee corporation, Sydney Australia",
        subtitle: "",
        exp: [
          "Acted as change management  consultant to deal with the queries on the new system (ICBS)",
          "Team Leader for a team of 20 officers for training and development",
          "Responsible for trouble shooting of Application Software.",
          "Master trainer for newly inducted officers.",
        ],
      },
      {
        title:
          "Project Manager energy conservation & management department, Astina Electronics Singapore",
        subtitle: "",
        exp: [
          "Supervised the installation of energy saving equipment.",
          "Developed the energy saving plan for consumers of electricity.",
          "Supervise the testing and approval of energy saving products through productivity and standard board of Singapore.",
          "Participated in seminars, conference and workshops to identify the most modern practices in the field of energy conservation.",
        ],
      },
      {
        title:
          "Establishment of R&D and testing facilities (Astina Electronics Singapore)",
        subtitle: "",
        exp: [
          "Established R&D facilities at Astina Electronics Singapore for energy saving equipment",
          "Supervised the testing and approval of energy saving products through productivity and standard board of Singapore.",
        ],
      },
      {
        title:
          "Electrical engineer office of the project director, R&RE department, Area electricity board Multan.",
        subtitle: "",
        exp: [
          "Study and preparation of long-term area planning proposals.",
          "Supervise the commissioning and installation of electrical equipment ensuring that the design and installations are within specifications and regulations.",
          "Supervise implementation, use and maintenance of line equipment.",
          "Determination of proper sited for electrification and review settings as requested by operating division personnel",
          "Preparation of drawings and plans of electrical installations ensuring designs and installations are within specifications and regulations",
          "Writing fortnightly and monthly reports and updating senior management with the progress of the project.",
        ],
      },
      {
        title:
          "Design engineer Design T&G department, Water and power development authority, Lahore",
        subtitle: "",
        exp: [
          "Commercial and technical evaluation of tenders for the transmission and grid station equipment.",
          "Investigation and analysis of submitted bids and tenders and preparation of technical report",
          "Performed computer modeled feeder analysis for system expansion energy loss reduction and reliable service to the existing consumer.",
          "Dealing with consultants and contractors after the allocation of tenders.",
          "Working in a team environment with project management department to discuss the final allocation of tender.",
        ],
      },
      {
        title:
          "Quality of TVET in institutes (PIQC & Intel training of teachers)",
        subtitle: "",
        exp: [
          "Expert panelist on enhancing Quality Of TVET In Institutes, review, understand and explain the paradigms of total quality management (TQM); identify the applications of Quality Tools and Techniques in implementing Total Quality Management for TVET institutions and prepare an action plan for implementing TQM at TVET institutions and organizations for constant improvement",
          "'Role of Academic Performance Audit for Quality Assurance in Technical and Vocational Training' at Pakistan’s first National Conference on Quality Assurance in Education. ",
          "'Intel Teach to the future Program'",
          "More than 1000 teachers were trained in TEVTA under training of teachers- Intel Teach to the future with continuous efforts and interest in professional development of teachers in integrated IT skills.",
        ],
      },
      {
        title:
          "Implementation of change management system (Australian Guarantee Corporation Limited Australia)",
        subtitle: "",
        exp: [
          "Reviewed and recommended comprehensive human resource development strategies for strengthening the implementation ICBS system at AGC ",
          "Successfully implemented Change Management system within the organization; Master trainer for newly inducted officers who were trained to learn the new software and techniques to facilitate customers and handle conflict management; develop a of competency standard training to upgrade the present system of pre- and in- customer service training; train on sensitive issues related to multicultural aspects and gender for customer service",
          "Developed the energy saving plan for large Industrial consumers of electricity in Singapore ",
        ],
      },
      {
        title:
          "Research on Institutions offering productivity courses in Pakistan (APO Japan)",
        subtitle:
          "Worked with APO Japan and conducted research on institutions offering productivity courses in Pakistan. The main features of this study are:",
        exp: [
          "Existing national scenario",
          "Education and training system in Pakistan",
          "Evolution of productivity management system",
          "Evaluation of existing productivity courses",
          "The institutions offering productivity courses in Pakistan",
          "Strategic actions and improvement plans",
          "Action plan for Implementation",
        ],
      },
      {
        title:
          "Accreditation of engineering programs with PEC and launch of new six technical vocational programs with PSDF (Swedish College of Engineering and Technology Rahim Yar Khan Pakistan)",
        subtitle: "",
        exp: [
          "Launched six vocational courses of 3 months to 1 year duration",
          "TVET expert for Technical , Vocational Education and Training (TVET) for 9  technical institutes under Swedish Group  running DAE program in Electrical, Electronics, Mechanical ,Chemical and Computer information Technology",
          "Got the electrical engineering & mechanical engineering programs accredited from Pakistan engineering ",
          "Design and development of intelligent computational techniques for power quality data monitoring and management (Victoria University Australia)",
          "Developed a new intelligent strategy to manage the quality of electrical supply of  power system",
          "Designed intelligent computational techniques to enhance the efficiency reliability and stability of the power distribution system",
          "Analysis of power quality parameters for 66/22kv zone substations for management of power quality for united energy distribution system Australia",
        ],
      },
      {
        title:
          "TVET reform policy formulation (Technical Education & Vocational Training Authority Punjab)",
        subtitle: "",
        exp: [
          "Developed 3 years Strategic Plan for imparting technical, vocational and commerce education in more than 400 institutes under the government of Punjab",
          "Developed TVET short courses based on working with the competency based training (CBT) approach and manuals with the help of major industry partners in electrical, civil, mechanical and textile sector for imparting employable skills under TEVT special training program. ",
          "Provide standardized and credible TEVT assessment/evaluation and certification below degree level within the territorial limits of the province of the Punjab and such other areas as may be notified by the government; Evaluated the development and process of integration and how far the Technical Education system has established the linkages with other projects on TVET in Pakistan",
          "Designed Academic Model for the development and promotion of Academic programs in various sectors for technical, vocational and commerce institutes spread all over Punjab. ",
          "Contributed towards the establishment of technical and vocational training of The National Think Tank on technical and vocational Education",
          "Launched HND courses focused on competency were signed for on Electrical job training, placement, and training of trainers with Siemens, Nokia , Telenor and various MOU’s",
        ],
      },
      {
        title:
          "TVET teacher’s qualifications in Pakistan (National Vocational & Technical Training Commission Islamabad -NAVTTC)",
        subtitle: "",
        exp: [
          "NAVTTC is an apex body at national level to regulate, facilitate and provide policy direction in Vocational & Technical Training; Review and evaluate the existing practices in the design, implementation and evaluation of TVET curriculum practiced by the teachers, an develop a training program particularly in the development of competency-based curriculum, in the field of technical and vocational education and Training facilitating, and exchange of experiences among the participating TVET Institutions",
          "Developed National Qualification framework for TVET teachers and linked qualification with promotion of teachers in TVET sector ",
        ],
      },
      {
        title:
          "General Manager (Academics) technical education and vocational training Punjab Lahore Pakistan",
        subtitle: "",
        exp: [
          "To act as Principal Resource Person to Chairman TEVTA on all academic, training and skill up gradation matters",
          "Administrative head of technical teachers training and staff development institute in Punjab",
          "Supervised  TVET reform policy formulation and implementation regarding the competency based training( CBT) & curriculum development; introduced competency based curricula in the areas of TVET; Organized the training of more than 5000 Teachers for skill up- gradation and efficient institute management Programs ",
          "Provided technical assistance on strategic planning, policy and program implementation for technical and vocational education policies & Programs ",
          "Administrative head of R& D department, curriculum department, teachers training department, apprenticeship department and academic audit and registration department",
          "Competent Authority for apprenticeship training in industries of Punjab under 1962 ordinance",
          "Competent Authority for registration of private institutes with TEVTA Punjab",
          "Conduction of training courses (SAARC member countries & Korea Productivity Center Seoul, Korea)",
          "Conducted training to the participants from SAARC member countries and gave lecture on the topic “Role of Technical and Vocational Education in Youth Employment Generation in South Asian context ; also presented the   impact of culture and gender issues ",
          "Developed competency based training model as “Multi-Country Study Mission on IN-company Training programs”",
          "The model was developed based on the study of in-company training and development activities conducted by the private and public enterprises of the host country and examine the Korean approach to needs identification, program development, implementation, and evaluation. Also review the Steps required to perform tasks;  Knowledge and skills required to perform tasks; Safety factors; Tools, equipment and supplies; Worker decisions that may affect",
          "In-Country Program reviewed the key concept of PPP for expanding job opportunities and the growth of the linkages with TVET in Pakistan; also reviewed the entrepreneurial skill development for TVET Female Teachers for Technician Education",
        ],
      },
    ],
    research: [
      {
        title:
          "Research on Institutions offering productivity courses in Pakistan (APO Japan)",
        subtitle:
          "Worked with APO Japan as National Consultant (Pakistan) and conducted research on institutions offering productivity courses in Pakistan. The main features of this study are:",
        exp: [
          "Existing national scenario.",
          "Education and training system in Pakistan.",
          "Evolution of productivity management system.",
          "Evaluation of existing productivity courses.",
          "The institutions offering productivity courses in Pakistan.",
          "Strategic actions and improvement plans.",
          "Action plan for Implementation.",
          "Design and development of intelligent computational techniques for power quality data monitoring and management (Victoria University Australia)",
          "Developed a new intelligent strategy to manage the quality of electrical supply of power system.",
          "Designed intelligent computational techniques to enhance the efficiency reliability and stability of the power distribution system.",
          "Analysis of power quality parameters for 66/22kv zone substations for management of power quality for united energy distribution system Australia.",
          "Conduction of training courses (SAARC member countries & Korea Productivity Center Seoul, Korea)",
          "Conducted training to the participants from SAARC member countries and gave lecture on the topic “Role of Technical and Vocational Education in Youth Employment Generation in South Asian context; also presented the impact of culture and gender issues.",
          "Developed competency based training model as “Multi-Country Study Mission on IN-company Training programs.",
          "The model was developed based on the study of in-company training and development activities conducted by the private and public enterprises of the host country and examine the Korean approach to needs identification, program development, implementation, and evaluation. Also review the Steps required to perform tasks; Knowledge and skills required to perform tasks; Safety factors; Tools, equipment and supplies; Worker decisions that may affect.",
          "In Country Program reviewed the key concept of PPP for expanding job opportunities and the growth of the linkages with TEVTA in Pakistan; also reviewed the entrepreneurial skill development for TEVTA Female Teachers for Technician Education.",
        ],
      },
      {
        title:
          "Establishment of R&D and testing facilities (Astina Electronics Singapore)",
        subtitle: "",
        exp: [
          "Established R&D facilities at Astina Electronics Singapore for energy saving equipment.",
          "Supervised the testing and approval of energy saving products through productivity and standard board of Singapore.",
        ],
      },
      {
        title: "Australian Guarantee Corporation Sydney Australia",
        subtitle: "",
        exp: [
          "Successfully implemented Change Management system within the organization.",
          "Master trainer for newly inducted officers who were trained to learn the new software and techniques to facilitate customers and handle conflict management.",
          "Responsible for conflict management and customer satisfaction.",
        ],
      },
      {
        title: "Astina Electrical-Electronics Singapore",
        subtitle: "",
        exp: [
          "Involved in R&D for certification of products from productivity and standards board of Singapore.",
          "Supervised key project for installation of energy saving equipment at Changi International Airport Singapore.",
          "Developed the energy saving plan for large Industrial consumers of electricity in Singapore and Malaysia.",
          "Participated in Seminars, conferences and workshop to identify the most modern practices in the field of energy conservation.",
        ],
      },
      {
        title:
          "Expert TEVTA/Principal Swedish College of engineering & technology Rahim Yar Khan",
        subtitle: "",
        exp: [
          "Promoted the comprehensive development of the institution as the Head of the Institution.",
          "Collaborated with Punjab Skill Development Fund and won the grant of 27 million for technical training.",
        ],
      },
      {
        title:
          "TEVTA reform policy formulation (Technical Education & Vocational Training Authority Punjab)",
        subtitle: "",
        exp: [
          "Developed 3 years Strategic Plan for imparting technical, vocational and commerce education in more than 400 institutes under the government of Punjab.",
          "Developed TEVTA short courses based on working with the competency based training (CBT) approach and manuals with the help of major industry partners in electrical, civil, mechanical and textile sector for imparting employable skills under TEVT special training program.",
          "Provide standardized and credible TEVT assessment/evaluation and certification below degree level within the territorial limits of the province of the Punjab and such other areas as may be notified by the government; Evaluated the development and process of integration and how far the Technical Education system has established the linkages with other projects on TEVTA in Pakistan",
          "Designed Academic Model for the development and promotion of Academic programs in various sectors for technical, vocational and commerce institutes spread all over Punjab.",
          "Contributed towards the establishment of technical and vocational training of The National Think Tank on technical and vocational Education.",
          "Launched HND courses focused on competency were signed for on Electrical job training, placement, and training of trainers with Siemens, Nokia, Telenor and various MOU’s.",
        ],
      },
      {
        title:
          "TEVTA teacher’s qualifications in Pakistan (National Vocational & Technical Training Commission Islamabad -NAVTTC)",
        subtitle: "",
        exp: [
          "NAVTTC is an apex body at national level to regulate, facilitate and provide policy direction in Vocational & Technical Training; Review and evaluate the existing practices in the design, implementation and evaluation of TEVTA curriculum practiced by the teachers, an develop a training program particularly in the development of competency-based curriculum, in the field of technical and vocational education and Training facilitating, and exchange of experiences among the participating TEVTA Institutions.",
          "Developed National Qualification framework for TEVTA teachers and linked qualification with promotion of teachers in TEVTA sector.",
        ],
      },
      {
        title:
          "Quality of TEVTA in institutes (PIQC & Intel training of teachers)",
        subtitle: "",
        exp: [
          "Expert panelist on enhancing Quality of TEVTA in Institutes, review, understand and explain the paradigms of total quality management (TQM); identify the applications of Quality Tools and Techniques in implementing Total Quality Management for TEVTA institutions and prepare an action plan for implementing TQM at TEVTA institutions and organizations for constant improvement.",
          "“Role of Academic Performance Audit for Quality Assurance in Technical and Vocational Training” at Pakistan’s first National Conference on Quality Assurance in Education Intel Teach to the future Program.",
          "More than 1000 teachers were trained in TEVTA under training of teachers- Intel Teach to the future with continuous efforts and interest in professional development of teachers in integrated IT skills.",
        ],
      },
      {
        title: "Water and Power Development Authority (WAPDA) Pakistan",
        subtitle: "",
        exp: [
          "Plan feasibility and preparation of long-term area planning and electrification proposals.",
          "Provided electricity to 200 villages under the Prime Minister Program for Village electrification (Asian Development Bank Project).",
          "Commercial and technical evaluation of bids for the transmission and grid station equipment as per scope of works.",
          "Performed computer modeled feeder analysis for system expansion energy loss reduction and reliable service to the existing consumer.",
        ],
      },
    ],
  }),

  computed: {
    currentTab() {
      return this.$store.state.currentTab;
    },
  },
};
</script>

<style scoped>
.mainPic {
  border: 10px solid white;
  border-radius: 10px;
}
.logoPic {
  width: 150;
  height: 150;
}
.cImage {
  background-position: center top;
}
.v-application ul {
  padding: 10px 50px 20px 50px;
}
.v-application li {
  border-radius: 10px;
  padding: 5px;
}
</style>
