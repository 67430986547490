var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[_c('v-navigation-drawer',{staticClass:"red darken-4",attrs:{"temporary":"","absolute":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{attrs:{"color":"red darken-4 elevation-0"}},[_c('v-card-text',{staticClass:"pa-1"},[_c('div',{staticClass:"d-flex justify-center pt-3"},[_c('v-img',{staticClass:"profilePic elevation-12",attrs:{"src":"./img/zahirjavedparacha.jpeg","max-height":"150","max-width":"150"}})],1),_c('br'),_c('div',{staticClass:"text-center white--text"},[_c('h3',[_vm._v("Dr. Zahir Javed Parahca")]),_c('h4',[_vm._v("C.E.O")]),_c('p',[_vm._v("Penta-H Solar Inc")])]),_c('div',{staticClass:"divider"})])],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":"","dark":""}},_vm._l((_vm.tabs),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.tabselected(index)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-divider'),_c('v-list',{staticClass:"transparent",attrs:{"dark":"","dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("+923018445040")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-mail")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("zahir.paracha@live.vu.edu.au")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-map-marker")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Lahore, Pakistan")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked(
              'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
            )}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("LinkedIn")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked('https://www.facebook.com/zahir.paracha.7')}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Facebook")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked(
              'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
            )}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-youtube")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Youtube")])],1)],1)],1)],1),_c('v-app-bar',{attrs:{"app":"","dense":"","extended":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-container',{staticClass:"d-none d-md-flex"},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"red darken-4","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"red darken-4"}}),_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:index,staticClass:"red--text text--darken-4"},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1),_c('v-container',{staticClass:"d-flex d-md-none"},[_vm._v(" "+_vm._s(_vm.tabs[_vm.tab].title)+" ")])]},proxy:true}])},[_c('v-app-bar-nav-icon',{staticClass:"d-flex d-md-none",on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_vm._v("Zahir Javed Paracha")]),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked('https://www.facebook.com/zahir.paracha.7')}}},[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked(
            'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
          )}}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked(
            'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
          )}}},[_c('v-icon',[_vm._v("mdi-youtube")])],1)],1),_c('div',{staticClass:"ma-1"}),_c('v-main',[_c('v-container',[_c('v-row',[_c('div',{staticClass:"col-md-3 pa-0 d-none d-md-flex"},[_c('v-card',{attrs:{"color":"red darken-4"}},[_c('v-card-text',{staticClass:"pa-1"},[_c('div',{staticClass:"d-flex justify-center pt-3"},[_c('v-img',{staticClass:"profilePic elevation-12",attrs:{"src":"./img/zahirjavedparacha.jpeg","max-height":"150","max-width":"150"}})],1),_c('br'),_c('div',{staticClass:"text-center white--text"},[_c('h3',[_vm._v("Dr. Zahir Javed Parahca")])]),_c('div',{staticClass:"divider"}),_c('v-list',{staticClass:"transparent",attrs:{"dark":"","dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-briefcase")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("C.E.O")]),_c('v-list-item-subtitle',[_vm._v("Penta-H Solar Inc")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("+923018445040")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-mail")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("zahir.paracha@live.vu.edu.au")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-map-marker")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Lahore, Pakistan")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked(
                        'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
                      )}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("LinkedIn")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked(
                        'https://www.facebook.com/zahir.paracha.7'
                      )}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Facebook")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.socialClicked(
                        'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
                      )}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-youtube")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Youtube")])],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"col-md-9 py-0"},[_c('router-view')],1)],1)],1)],1),_c('div',{staticClass:"ma-2"}),_c('v-footer',{staticClass:"black",attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked('https://www.facebook.com/zahir.paracha.7')}}},[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked(
            'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
          )}}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.socialClicked(
            'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
          )}}},[_c('v-icon',[_vm._v("mdi-youtube")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }