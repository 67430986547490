<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        temporary
        absolute
        class="red darken-4"
      >
        <v-card color="red darken-4 elevation-0">
          <v-card-text class="pa-1">
            <div class="d-flex justify-center pt-3">
              <v-img
                class="profilePic elevation-12"
                src="./img/zahirjavedparacha.jpeg"
                max-height="150"
                max-width="150"
              ></v-img>
            </div>
            <br />
            <div class="text-center white--text">
              <h3>Dr. Zahir Javed Parahca</h3>
              <h4>C.E.O</h4>
              <p>Penta-H Solar Inc</p>
            </div>
            <div class="divider" />
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-list dense nav dark>
          <v-list-item
            v-for="(item, index) in tabs"
            :key="index"
            link
            @click="tabselected(index)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list class="transparent" dark dense>
          <v-list-item>
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>+923018445040</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar><v-icon>mdi-mail</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                >zahir.paracha@live.vu.edu.au</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar
              ><v-icon>mdi-map-marker</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Lahore, Pakistan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            @click="
              socialClicked(
                'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
              )
            "
          >
            <v-list-item-avatar
              ><v-icon>mdi-linkedin</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>LinkedIn</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="socialClicked('https://www.facebook.com/zahir.paracha.7')"
          >
            <v-list-item-avatar
              ><v-icon>mdi-facebook</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Facebook</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="
              socialClicked(
                'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
              )
            "
          >
            <v-list-item-avatar
              ><v-icon>mdi-youtube</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>Youtube</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app dense extended>
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"
          ><v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>Zahir Javed Paracha</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="socialClicked('https://www.facebook.com/zahir.paracha.7')"
          class="d-none d-md-flex"
          ><v-icon>mdi-facebook</v-icon></v-btn
        >
        <v-btn
          icon
          @click="
            socialClicked(
              'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
            )
          "
          class="d-none d-md-flex"
          ><v-icon>mdi-linkedin</v-icon></v-btn
        >
        <v-btn
          icon
          @click="
            socialClicked(
              'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
            )
          "
          class="d-none d-md-flex"
          ><v-icon>mdi-youtube</v-icon></v-btn
        >
        <template v-slot:extension>
          <v-container class="d-none d-md-flex">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="red darken-4"
              grow
            >
              <v-tabs-slider color="red darken-4"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in tabs"
                :key="index"
                class="red--text text--darken-4"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </v-container>
          <v-container class="d-flex d-md-none">
            {{ tabs[tab].title }}
          </v-container>
        </template>
      </v-app-bar>
      <div class="ma-1"></div>
      <v-main>
        <v-container>
          <v-row>
            <div class="col-md-3 pa-0 d-none d-md-flex">
              <v-card color="red darken-4">
                <v-card-text class="pa-1">
                  <div class="d-flex justify-center pt-3">
                    <v-img
                      class="profilePic elevation-12"
                      src="./img/zahirjavedparacha.jpeg"
                      max-height="150"
                      max-width="150"
                    ></v-img>
                  </div>
                  <br />
                  <div class="text-center white--text">
                    <h3>Dr. Zahir Javed Parahca</h3>
                  </div>
                  <div class="divider" />
                  <v-list class="transparent" dark dense>
                    <v-list-item>
                      <v-list-item-avatar
                        ><v-icon>mdi-briefcase</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>C.E.O</v-list-item-title>
                        <v-list-item-subtitle
                          >Penta-H Solar Inc</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-avatar
                        ><v-icon>mdi-phone</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>+923018445040</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar
                        ><v-icon>mdi-mail</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title
                          >zahir.paracha@live.vu.edu.au</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar
                        ><v-icon>mdi-map-marker</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>Lahore, Pakistan</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      @click="
                        socialClicked(
                          'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
                        )
                      "
                    >
                      <v-list-item-avatar
                        ><v-icon>mdi-linkedin</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>LinkedIn</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="
                        socialClicked(
                          'https://www.facebook.com/zahir.paracha.7'
                        )
                      "
                    >
                      <v-list-item-avatar
                        ><v-icon>mdi-facebook</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>Facebook</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="
                        socialClicked(
                          'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
                        )
                      "
                    >
                      <v-list-item-avatar
                        ><v-icon>mdi-youtube</v-icon></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title>Youtube</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
            <v-col class="col-md-9 py-0">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <div class="ma-2"></div>
      <v-footer class="black" dark>
        <v-btn
          icon
          @click="socialClicked('https://www.facebook.com/zahir.paracha.7')"
          ><v-icon>mdi-facebook</v-icon></v-btn
        >
        <v-btn
          icon
          @click="
            socialClicked(
              'https://www.linkedin.com/in/dr-zahir-javed-paracha-68199a6?originalSubdomain=pk'
            )
          "
          ><v-icon>mdi-linkedin</v-icon></v-btn
        >
        <v-btn
          icon
          @click="
            socialClicked(
              'https://youtube.com/channel/UC9VlCc_fcPZlg8Oem85---w'
            )
          "
          ><v-icon>mdi-youtube</v-icon></v-btn
        >
      </v-footer>
    </v-app>
  </div>
</template>
<script>
export default {
  data: () => ({
    drawer: false,
    currentRoute: "/",
    tab: 0,
    tabs: [
      { title: "ABOUT", icon: "" },
      { title: "LEADERSHIP ROLES", icon: "" },
      { title: "ACADEMIC CONTRIBUTIONS", icon: "" },
      { title: "INDUSTRY CONTRIBUTIONS", icon: "" },
      { title: "ENTREPRENEUR", icon: "" },
      { title: "RESEARCH PROJECTS", icon: "" },
    ],
  }),
  watch: {
    $route(to, from) {
      this.currentRoute = to.path;
    },
    tab(newValue, oldValue) {
      this.$store.dispatch("currentTab", newValue);
    },
  },
  methods: {
    menuClicked(type) {
      this.$router.push(type);
    },
    tabselected(index) {
      this.drawer = false;
      this.tab = index;
    },
    socialClicked(path) {
      window.open(path, "_blank");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 230, 230);
}
.profilePic {
  border-radius: 100px;
}
.profilePic1 {
  border-radius: 30px;
}
.mainPicBack {
  background-color: rgb(9, 9, 59);
  color: white;
}
.backColor {
  background-color: rgb(230, 230, 230);
}
.roundedBox {
  border-radius: 10px;
}
.redBox {
  color: white;
}
.divider {
  height: 1px;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}
.secondaryToolbar {
  height: 40px;
}
.handCursor {
  cursor: pointer;
}
a {
  text-decoration: none;
}
</style>
