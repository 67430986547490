<template>
  <div>
    <div class="mainPicBack py-12">
      <v-container class="d-flex">
        <v-img class="mainPic" max-height="250" max-width="250" src="./img/zahirjavedparacha.jpeg"></v-img>
        <div class="pa-6">
          <h2>Dr Zahir Javed Paracha</h2>
          <p>Dr. Zahir Javed Paracha is an HEC approved PhD Supervisor. He successfully won AUSTRALIAN POSTGRADUATE AWARD and completed his PhD degree in Electrical Engineering from Victorila University Melbourne Australia. He has over 30 years comprehensive range of experience which includes academic leadership, strategic academic policy planning & development, academic governance and administration, teaching & research, human resource development skills development, teaching & research</p>
        </div>
      </v-container>
    </div>
    <v-container>
        <v-timeline>
            <v-timeline-item v-for="(degree, i) in degrees" :key="i" small>
            <template v-slot:opposite>
                <span :class="`headline font-weight-bold`" v-text="degree.year"></span>
            </template>
            <v-card class="elevation-2">
                <v-card-title>{{degree.degree}}</v-card-title>
                <v-card-text>{{degree.place}}</v-card-text>
            </v-card>
            </v-timeline-item>
        </v-timeline>
    </v-container>
  </div>
</template>
<script>
export default {
    name: "Education",
    data: () => ({
        degrees: [
        {
            year: "1989",
            degree: 'Bachelor of Science in Electrical Engineering',
            place: 'University of Engineering & Technology, Lahore, Pakistan,',
        },
        {
            year: "1997",
            degree: 'Master of Engineering Studies',
            place: 'University of Wollonging, Australia',
        },
        {
            year: "2000",
            degree: 'Management Communication Graduate Program',
            place: 'Southern Sydney Institute of TAFE, Australia',
        },
        {
            year: "2005",
            degree: 'University of Punjab, Lahore, Pakistan',
            place: 'Master of Science in Total Quality Management (TQM)',
        },
        {
            year: "2010",
            degree: 'Research Commercialization Program',
            place: 'The University of Melbourne, Australia',
        },
        {
            year: "2012",
            degree: 'Victoria University Melbourne, Australia',
            place: 'PhD Electrical Engineering',
        },
        ],
    }),
};
</script>